<template>
  <div class="form-group">
    <input
      v-model="value"
      type="text"
      :name="name"
      :placeholder="placeholder"
      :class="{
        'is-invalid': isInvalid && errorMessage !== undefined,
        'not-empty': value,
      }"
    />
    <label for="" class="form-group__label">{{ placeholder }}</label>
    <div v-if="isInvalid && errorMessage !== undefined" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const { value, errorMessage, errors, meta } = useField(props.name);

const isInvalid = !!errors.value;
</script>

<style></style>
