import { defineStore } from "pinia";

const useLoaderStore = defineStore("counter", {
  state: () => ({ requestsPending: 0 }),
  getters: {
    show: (state) => state.requestsPending > 0,
  },
  actions: {
    pending() {
      this.requestsPending++;
    },
    done() {
      if (this.requestsPending > 0) {
        this.requestsPending--;
      }
    },
    clear() {
      this.requestsPending = 0;
    },
  },
});

export default useLoaderStore;
