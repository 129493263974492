<template>
  <form class="b-form" @submit.stop="submitForm">
    <div class="row">
      <div class="col-lg-5 col-md-6 col-xl-6">
        <div class="form-group-item">
          <FormText name="name" placeholder="Имя" />
          <FormText name="phone" placeholder="Телефон" />
          <FormText name="email" placeholder="Email" />
          <div class="form-group">
            <div class="contact-type">
              <label
                class="ct-label-checkbox"
                :class="{
                  invalid:
                    isConsentInvalidValid && consentErrorMessage !== undefined,
                }"
              >
                Согласен на обработку
                <span  class="contact-page__link">персональных данных</span>
                <input type="checkbox" v-model="consentValue" />
                <span class="checkmark"></span>
              </label>
              <div
                v-if="
                  isConsentInvalidValid && consentErrorMessage !== undefined
                "
                class="error-message"
              >
                {{ consentErrorMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 offset-lg-2 offset-xl-0 col-md-6 col-xl-6">
        <FormTextArea name="msg" placeholder="Ваше сообщение" />
        <div class="b-form__wrap-btn">
          <app-button type="submit"  :is-full-width="true"
                      :is-disabled="isDisabled"
            >отправить сообщение</app-button
          >
        </div>
      </div>
    </div>
  </form>
  <div class="form-error" ref="formError">
    Произошла ошибка. Попробуйте еще раз, или свяжитесь с нами другим способом.
  </div>
  <div class="form-success" ref="formSuccess">
    Сообщение успешно отправлено!
  </div>
</template>

<script setup>
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import AppButton from "@/components/AppButton.vue";
import FormText from "@/views/FormText.vue";
import FormTextArea from "@/views/FormTextArea.vue";
import { computed, onMounted, ref } from "vue";
import apiClient from "@/services/http";
const isDisabled = ref(false);
const formError = ref(null);
const formSuccess = ref(null);
onMounted(() => {
  formError.value = document.querySelector('.form-error');
  formSuccess.value = document.querySelector('.form-success');
});
const form = useForm({
  validationSchema: yup.object({
    name: yup.string().required("Введите имя"),
    phone: yup.string().required("Введите телефон"),
    email: yup
      .string()
      .email("Введите корректный email")
      .required("Введите email"),
    msg: yup.string(),
    consent: yup
      .boolean()
      .required()
      .oneOf([true], "Поле должно быть отмечено"),
  }),
  initialValues: {
    name: "",
    phone: "",
    email: "",
    msg: "",
    consent: false,
  },
});

const name = useField("name");
const phone = useField("phone");
const email = useField("email");
const msg = useField("msg");
const {
  errorMessage: consentErrorMessage,
  errors: consentErrors,
  value: consentValue,
} = useField("consent");

const isConsentInvalidValid = !!consentErrors.value;
const submitForm = form.handleSubmit(async (values) => {

  try {
    isDisabled.value = true;
    formError.value.style.display = 'none';

    const response = await apiClient.post("/forms2/v1/feedback",
       JSON.stringify(values)
    );
    // Обработка ответа сервера
    if (response.status === 200) {
      isDisabled.value = false;
      formError.value.style.display = 'none';
      formSuccess.value.style.display = 'block';
      form.resetForm();
      setTimeout(function () {
        formSuccess.value.style.display = 'none';
      }, 5000); // 10000 миллисекунд = 10 секунд
      console.log("Данные успешно отправлены");
    } else {
      console.error("Ошибка при отправке данных");
      formError.value.style.display = 'block';
      isDisabled.value = false;
    }
  } catch (error) {
    console.error("Ошибка запроса", error);
    formError.value.style.display = 'block';
    isDisabled.value = false;
  }
});
</script>
<style lang="scss">
.error-message {
  color: #d24a47;
}
.form-error{
  margin-top: 20px;
  text-align: center;
  color: #d24a47;
  display: none;
}
.form-success{
  margin-top: 20px;
  text-align: center;
  color: #1ea20f;
  display: none;
}
</style>
