<template>
  <div class="line-btn">
    <span><slot></slot></span>
  </div>
</template>
<style>
.line-btn {
  color: #fff;
  cursor: pointer;
}
</style>
