import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cases",
    name: "cases",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PortfolioView.vue"),
  },
  {
    path: "/cases/:caseId", // Динамический маршрут для отображения деталей кейса
    name: "caseDetail",
    component: () =>
      import("../views/PortfolioDetailView.vue"),
  },
  // default redirect to home page
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
export default router;
