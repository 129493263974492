import axios, { AxiosInstance } from "axios";
import useLoaderStore from "@/store/loader";
import pinia from "@/store/store";

let tmpEnv;
tmpEnv = process.env.VUE_APP_API_URL || "";
tmpEnv = tmpEnv.replace(
  "{rel}",
  window.location.href.split("#")[0].slice(0, -1)
);

const config = {
  baseURL:
    (tmpEnv ||
      process.env.baseURL ||
      process.env.apiUrl ||
      "http://95.165.208.54:62048") + "/wp-json",
  headers: {
    "Content-type": "application/json",
  },
};
const storeLoader = useLoaderStore(pinia);
const apiClient: AxiosInstance = axios.create(config);
// add request interceptors
apiClient.interceptors.request.use(
  function (config) {
    storeLoader.pending();
    return config;
  },
  function (error) {
    storeLoader.done();
    return Promise.reject(error); //
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    storeLoader.done();
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      console.log(401);
      // const storeAuth = useAuthStore();
      // storeAuth.logout();
    }
    storeLoader.done();
    return Promise.reject(error);
  }
);

export default apiClient;
