import { defineStore } from "pinia";

export const useScrollStore = defineStore("scroll", {
  state: () => ({
    activeSection: null as string | null,
  }),
  actions: {
    setActiveSection(sectionId: string) {
      this.activeSection = sectionId;
    },
    scrollToSection(sectionId: string) {
      const targetSection = document.querySelector(sectionId);
      if (targetSection) {
        const scrollContainer = document.querySelector(".b-content");
        if (scrollContainer) {
          const containerRect = scrollContainer.getBoundingClientRect();
          const sectionRect = targetSection.getBoundingClientRect();
          const offset =
            sectionRect.top - containerRect.top + scrollContainer.scrollTop;
          scrollContainer.scrollTo({
            top: offset,
            behavior: "smooth",
          });
          this.setActiveSection(sectionId);
        }
      }
    },
  },
});
