<template>
  <div class="b-form__label">Сообщение</div>
  <div class="form-group">
    <textarea
      v-model="value"
      :name="name"
      :placeholder="placeholder"
    ></textarea>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
  name: { type: String, required: true },
  placeholder: { type: String, default: "" },
});

const { value, errorMessage } = useField(props.name);
</script>
